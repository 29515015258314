body {
    margin: 0;
    font-family: "Larsseit", "Montserrat", Helvetica, Roboto, Arial, sans-serif;
}

.uk-landing {
    background-color: #3a3f51;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.uk-landing .uk-landing__tools {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
}

.uk-landing .uk-main__heading, .uk-landing p {
    color: #fff;
}

/* Buttons */

.uk-landing .uk-button {
    outline: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    -webkit-appearance: none;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    font-family: inherit;
    font-size: 0.9rem;
    padding: 0.85em 1em;
    border: 1px solid #2681DB;
    border-radius: 3px;
    transition: all 0.25s ease-out;
    font-weight: 400;
}

.uk-landing .uk-button.asLink {
    padding: unset;
    border: unset;
    width: unset;
    text-decoration: none;
}

.uk-landing .uk-button.white.asLink {
    background-color: transparent;
    color: #ffffff;
}

.uk-landing .uk-button.white.asLink:hover {
    background-color: transparent;
    color: #cccccc;
}

.uk-landing .uk-button.white {
    border-color: #fff;
}

.uk-landing .uk-button.white.outline {
    background-color: transparent;
    color: #ffffff;
}

.uk-landing .uk-button.white.outline:hover {
    background-color: #ffffff;
    color: #0a0a0a;
}

/* Spacing */

.uk-landing .margin-left-1 {
    margin-left: 1rem !important;
}

.uk-landing .margin-top-1 {
    margin-top: 1rem !important;
}
